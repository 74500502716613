/* =================================== */
/*  4. Header
/* =================================== */

#header {
  @include transition(all .5s ease);
  .navbar {
    padding: 0px;
  }
  .logo {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
}


/*=== 4.1 Navigation ===*/

.navbar-light .navbar-nav {
  .active > .nav-link {
    color: #0c2f55;
  }
  .nav-link {
    &.active, &.show {
      color: #0c2f55;
    }
  }
  .show > .nav-link {
    color: #0c2f55;
  }
}

.primary-menu {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  height: auto !important;
  -webkit-box-ordinal-group: 0;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background: #fff;
  border-bottom:1px solid #efefef;
  &.bg-transparent {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: none;
	border-bottom: 1px solid rgba(250, 250, 250, 0.3);
  }
   &.sticky-on{
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1020;
	left: 0;
	@include box-shadow(0px 0px 10px rgba(0, 0, 0, 0.05));
	-webkit-animation: slide-down 0.7s;
	-moz-animation: slide-down 0.7s;
	animation: slide-down 0.7s;
	@-webkit-keyframes slide-down { 0% { opacity:0; transform:translateY(-100%);}100% { opacity:1; transform:translateY(0);}}
	@-moz-keyframes slide-down { 0% { opacity:0; transform:translateY(-100%);}100% { opacity:1; transform:translateY(0);}}
	@keyframes slide-down { 0% { opacity:0; transform:translateY(-100%);}100% { opacity:1; transform:translateY(0);}}
	 
	 .none-on-sticky{
		display:none!important;
	 }
   }

  ul.navbar-nav > li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
	
	+ li {
    margin-left: 2px;
	}
    a {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
	
    > a:not(.btn) {
      height: 70px;
      padding:0px 0.85em;
      color: #252b33;
	  @include transition(all .2s ease);
      position: relative;
	  position:relative;
    }
	
    &:hover > a:not(.btn), & > a.active:not(.btn) {
      color: $primary-color;
	  @include transition(all .2s ease);
    }
	a.btn{padding: 0.4rem 1.4rem;}
    &.dropdown {
      .dropdown-menu li {
        > a:not(.btn) {
          padding: 8px 0px;
          background-color: transparent;
          color: #777;
		  @include transition(all .2s ease);
        }
        &:hover > a:not(.btn) {
          color: $primary-color;
		  @include transition(all .2s ease);
        }
      }
      .dropdown-menu {
		@include box-shadow(0px 0px 12px rgba(0, 0, 0, 0.176));
        border: 0px none;
        padding: 10px 15px;
        min-width: 230px;
        margin: 0;
        font-size: 14px;
        font-size: 0.875rem;
		z-index:1021;
      }
    }
  }
  .dropdown-menu-right {
    left: auto !important;
    right: 100% !important;
  }
  .dropdown-toggle:after {
    content: none;
  }
  
  &.navbar-line-under-text ul.navbar-nav > li {
	> a:not(.btn):after {
		position: absolute;
		content: "";
		height: 2px;
		width: 0;
		left: 50%;
		right: 0;
		bottom: 14px;
		background-color: transparent;
		color:#fff;
		border-bottom: 2px solid $primary-color;
		@include transition(all .3s ease-in-out);
		transform: translate(-50%,0) translateZ(0);
		-webkit-transform: translate(-50%,0) translateZ(0);
		}
		& > a:hover:not(.logo):after, & > a.active:after{
		width:calc(100% - 0.99em);
		}
	}
  
}

/*== Color Options ==*/

.primary-menu.navbar-text-light .navbar-toggler span {background:#fff;}

.primary-menu.navbar-text-light .navbar-nav > li{
  > a:not(.btn) {
    color: rgba(250, 250, 250, 0.9);
  }
}

.primary-menu.navbar-text-light.navbar-line-under-text .navbar-nav > li{
	& > a:not(.logo):after, & > a.active:after{
  border-color:rgba(250, 250, 250, 0.60);
  }
}


.sticky-on.bg-transparent:not(.navbar-text-light), .sticky-top .sticky-on-top.bg-transparent:not(.navbar-text-light){background-color:$white !important; border-bottom:1px solid #efefef!important;}
.sticky-on.navbar-text-light.bg-transparent, .sticky-top .sticky-on-top.navbar-text-light.bg-transparent{background-color:#111 !important; @include box-shadow(0px 0px 10px rgba(250, 250, 250, 0.03)); border-bottom:none!important;}

/* Hamburger Menu Button */
.navbar-toggler {
  width: 25px;
  height: 30px;
  padding: 10px;
  margin: 18px 15px;
  position: relative;
  border:none;
  @include rotate(0deg);
  @include transition(.5s ease-in-out);
  cursor: pointer;
  display: block;
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #3c3636;
    border-radius: 2px;
    opacity: 1;
    left: 0;
	@include rotate(0deg);
	@include transition(.25s ease-in-out);
    &:nth-child(1) {
      top: 7px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    &:nth-child(2) {
      top: 14px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    &:nth-child(3) {
      top: 21px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
	  
    }
  }
  &.show span {
    &:nth-child(1) {
      top: 3px;
      left: 3px;
  	  @include rotate(45deg);
    }
    &:nth-child(2) {
      width: 0%;
      opacity: 0;
    }
    &:nth-child(3) {
      top: 21px;
      left: 3px;
  	  @include rotate(-45deg);
    }
  }
  &:focus{
	box-shadow:none;
  }
}

.navbar-accordion{position:initial;}


// For Responsive Navbar
@mixin navbar-responsive {


.navbar-collapse {
  position: absolute;
  top: 99%;
  right: 0;
  left: 0;
  background: #fff;
  margin-top: 0px;
  z-index: 1000;
  @include box-shadow(0px 0px 15px rgba(0, 0, 0, 0.1));
  .navbar-nav {
    overflow: hidden;
    overflow-y: auto;
    max-height: 65vh;
    padding: 15px;
  }
}
ul.navbar-nav {
  li {
    display: block;
    border-bottom: 1px solid #eee;
    margin: 0;
    padding: 0;
    &:last-child {
      border: none;
    }
	+ li {
		margin-left: 0px;
	}
    &.dropdown > .dropdown-toggle > .arrow.show:after {
      -webkit-transform: translate(-50%, -50%) rotate(-45deg);
      transform: translate(-50%, -50%) rotate(-45deg);
	  @include transition(all .2s ease);
    }
	> a:hover:after, > a.active:after {
		content: none!important;
		width:0px!important;
	}
  }
  > li {
    > a:not(.btn) {
      height: auto;
      padding: 8px 0;
      position: relative;
    }
    &.dropdown {
      .dropdown-menu li > a:not(.btn) {
        padding: 8px 0;
        position: relative;
      }
      &:hover > a:after {
        content: none;
      }
      .dropdown-toggle .arrow:after {
        -webkit-transform: translate(-50%, -50%) rotate(134deg);
        transform: translate(-50%, -50%) rotate(134deg);
		@include transition(all .2s ease);
      }
    }
  }
  > li {
    &.dropdown .dropdown-menu {
      margin: 0;
	  @include box-shadow(none);
      border: none;
      padding: 0px 0px 0px 15px;
      .dropdown-menu {
        margin: 0;
      }
    }
  }
  
}

&.navbar-text-light .navbar-collapse{background:rgba(0,0,0,0.95);}

&.navbar-text-light .navbar-collapse ul.navbar-nav li{
	border-color:rgba(250,250,250,0.15);
}

}


.navbar-expand-none{
	@include navbar-responsive;
}


@include media-breakpoint-down(sm) {
	.navbar-expand-sm{
		@include navbar-responsive;
	}
}

@include media-breakpoint-down(md) {
	.navbar-expand-md{
		@include navbar-responsive;
	}
}

@include media-breakpoint-down(lg) {
	.navbar-expand-lg{
		@include navbar-responsive;
	}
}
@include media-breakpoint-down(xl) {
	.navbar-expand-xl{
		@include navbar-responsive;
	}
}